body,
html {
  background: #ff0000;
}

.app {
  height: 100vh;
  width: 100vw;
  color: #fb0006;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 625px) {
  .inner {
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.inner img {
  width: 40%;
  transform: rotate(0deg);
  pointer-events: none;
  user-select: none;
}

@media only screen and (max-width: 625px) {
  .inner img {
    margin-top: -100px;
    width: 90%;
  }
}
