h1 {
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 156px;
  line-height: 156px;
  letter-spacing: -5px;
}

p {
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -3px;
}
